<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/10-1400.jpg')" title="Formation Flying" subtitle="Fly with finesse" />
    <div class="program-description">
      <h1>What is Formation Flying?</h1>
      <p>Challenge yourself and have fun while improving your flight control skills.</p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
